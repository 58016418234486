<template>
  <div
    class="h-screen flex w-full vx-row no-gutter items-center justify-center page"
    style="color: #626262"
  >
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/3 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="card-bg page-card">
          <div>
            <div class="vx-row no-gutter justify-center items-center">
              <img
                src="@/application/assets/images/graphics/birth-date.png"
                style="height: 300px;"
              />
            </div>

            <div class="header-text">
              <h2>{{ $l.get("signUp.age.header") }}</h2>
              <p>{{ $l.get("signUp.age.body") }}</p>
            </div>

            <vs-row class="mb-12">
              <vs-col vs-w="4">
                <vs-select
                  v-model="year"
                  class="w-full select-large"
                  :label="$l.get('dictionary.year')"
                >
                  <vs-select-item
                    :key="index"
                    :value="item.value"
                    :text="item.text"
                    v-for="(item, index) in yearsOptions"
                    class="w-full"
                  />
                </vs-select>
              </vs-col>
              <vs-col vs-w="4">
                <vs-select
                  v-model="month"
                  class="w-full select-large"
                  :label="$l.get('dictionary.month')"
                >
                  <vs-select-item
                    :value="1"
                    :text="$l.get('dictionary.january')"
                    class="w-full"
                  />
                  <vs-select-item
                    :value="2"
                    :text="$l.get('dictionary.february')"
                    class="w-full"
                  />
                  <vs-select-item
                    :value="3"
                    :text="$l.get('dictionary.march')"
                    class="w-full"
                  />
                  <vs-select-item
                    :value="4"
                    :text="$l.get('dictionary.april')"
                    class="w-full"
                  />
                  <vs-select-item
                    :value="5"
                    :text="$l.get('dictionary.april')"
                    class="w-full"
                  />
                  <vs-select-item
                    :value="6"
                    :text="$l.get('dictionary.june')"
                    class="w-full"
                  />
                  <vs-select-item
                    :value="7"
                    :text="$l.get('dictionary.july')"
                    class="w-full"
                  />
                  <vs-select-item
                    :value="8"
                    :text="$l.get('dictionary.august')"
                    class="w-full"
                  />
                  <vs-select-item
                    :value="9"
                    :text="$l.get('dictionary.september')"
                    class="w-full"
                  />
                  <vs-select-item
                    :value="10"
                    :text="$l.get('dictionary.october')"
                    class="w-full"
                  />
                  <vs-select-item
                    :value="11"
                    :text="$l.get('dictionary.november')"
                    class="w-full"
                  />
                  <vs-select-item
                    :value="12"
                    :text="$l.get('dictionary.december')"
                    class="w-full"
                  />
                </vs-select>
              </vs-col>
              <vs-col vs-w="4">
                <vs-select
                  v-model="day"
                  class="w-full select-large"
                  :label="$l.get('dictionary.day')"
                >
                  <vs-select-item
                    :key="index"
                    :value="item.value"
                    :text="item.text"
                    v-for="(item, index) in daysOptions"
                    class="w-full"
                  />
                </vs-select>
              </vs-col>
            </vs-row>
            <vs-button
              color="primary"
              type="filled"
              v-if="isAgeValid"
              @click="onContinueClick"
              >{{ $l.get("signUp.age.submit-button") }}</vs-button
            >
            <div v-else style="text-align: center">
              <p style="color: #999999">
                {{ $l.get("signUp.age.invalid-age") }}
              </p>

              <vs-button
                color="primary"
                type="border"
                class="mt-2"
                @click="onReturnClick"
                >{{ $l.get("dictionary.return") }}</vs-button
              >
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import SelectContinueInteractor from "@/business/sign-up-age/select-continue";
import SelectReturnInteractor from "@/business/sign-up-age/select-return";
import SignUpAgeScreenController from "@/adapters/controllers/screen-sign-up-age";
import moment from "moment";

export default {
  screen_name: "sign-up-age",
  data() {
    const today = new Date();

    return {
      controller: null,
      interactors: {
        selectContinue: null,
        selectReturn: null
      },
      yearsOptions: [],
      daysOptions: [],
      year: today.getFullYear(),
      month: today.getMonth(),
      day: today.getDate(),
      data: null
    };
  },
  beforeMount() {
    this.controller = this.$injector.get(SignUpAgeScreenController);

    //{ INTERACTORS
    this.interactors.selectReturn = this.$injector.get(SelectReturnInteractor);
    this.interactors.selectContinue = this.$injector.get(
      SelectContinueInteractor
    );
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;

    this.updateOptions();
  },
  mounted() {},
  watch: {
    year() {
      this.updateOptions();
    },
    month() {
      this.updateOptions();
    }
  },
  computed: {
    isAgeValid() {
      return (
        moment().diff(moment([this.year, this.month - 1, this.day]), "years") >=
        18
      );
    }
  },
  methods: {
    setData(data) {
      this.data = data;
    },
    getData() {
      return this.data;
    },
    onContinueClick() {
      this.interactors.selectContinue.handle(
        this.$context,
        new Date(this.year, this.month - 1, this.day)
      );
    },
    onReturnClick() {
      this.interactors.selectReturn.handle(this.$context);
    },
    updateOptions() {
      const today = new Date();
      const dateDays = new Date(this.year, this.month, 0).getDate();
      if (this.day > dateDays) {
        this.day = dateDays;
      }

      this.yearsOptions = [];
      this.daysOptions = [];

      for (let i = 0; i < 150; i++) {
        const value = today.getFullYear() - i;
        this.yearsOptions.push({ value, text: value.toString() });
      }

      for (let i = 1; i <= dateDays; i++) {
        this.daysOptions.push({
          value: i,
          text: i.toString()
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.card-bg .vs-con-input-label {
  width: 100% !important;
  margin-top: 25px;
}
.card-bg .vs-button {
  width: 100% !important;
}
.header-text {
  margin-top: 30px;
  margin-bottom: 40px;
  text-align: center;
}
.inputs .vs-input {
  margin: 15px;
}
</style>
