import { render, staticRenderFns } from "./SignUpAge.vue?vue&type=template&id=860c7fb4&scoped=true"
import script from "./SignUpAge.vue?vue&type=script&lang=js"
export * from "./SignUpAge.vue?vue&type=script&lang=js"
import style0 from "./SignUpAge.vue?vue&type=style&index=0&id=860c7fb4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "860c7fb4",
  null
  
)

export default component.exports